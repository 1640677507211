<template lang="pug">
  .top-bar.filters
    .row.align-items-center
      .filter.col
        p.filter-title {{ $t(`logs.table.filters.created_at`) }}
        AppDatepicker(
          :value="filters['createdAt']"
          range
          @change="applyFilter('createdAt', $event)"
        )
      .filter.col
        p.filter-title {{ $t("logs.table.filters.types") }}
        AppDropdown.logs-type(
          checkbox
          batch-select
          multiple
          allow-empty
          :value="filters['types']"
          :items="types"
          @select="applyFilter('types', $event)"
        )
      .filter.col(v-if="showInventoryGroupFilter")
        p.filter-title {{ $t("logs.table.filters.inventory_groups") }}
        AppDropdown.inventory-groups-name(
          :loading="inventoryGroupsLoading"
          checkbox
          batch-select
          multiple
          allow-empty
          searchable
          :value="filters['inventoryGroups']"
          :items="inventoryGroups"
          order-direction="keep"
          @select="applyFilter('inventoryGroups', $event)"
        )
      .filter.col(v-if="showShopFilter")
        p.filter-title {{ $t("logs.table.filters.shops") }}
        AppDropdown.shop-name(
          :loading="shopsLoading"
          checkbox
          batch-select
          multiple
          allow-empty
          searchable
          :value="filters['shops']"
          :items="shops"
          order-direction="keep"
          @select="applyFilter('shops', $event)"
        )
      .filter.col
        p.filter-title {{ $t("logs.table.filters.car_classes") }}
        AppDropdown.car-class-name(
          :loading="carClassesLoading"
          checkbox
          batch-select
          multiple
          allow-empty
          searchable
          :value="filters['carClasses']"
          :items="carClasses"
          @select="applyFilter('carClasses', $event)"
        )
      .filter.col
        p.filter-title {{ $t("logs.table.filters.initiators") }}
        AppDropdown.initiator(
          :loading="accountsLoading"
          checkbox
          batch-select
          multiple
          allow-empty
          searchable
          :vue-key="initiatorsVueKey"
          :value="filters['initiators']"
          :items="initiators"
          order-direction="keep"
          @select="applyFilter('initiators', $event)"
        )
      .filter.col(v-if="tableWrapperClass === 'inventory-logs'")
        p.filter-title {{ $t(`logs.table.filters.${dateField}`) }}
        AppDatepicker(
          :value="filters['dateRange']"
          range
          :clearable="false"
          @change="applyFilter('dateRange', $event)"
        )
    .filter-actions
      .search
        AppSearch(
          :value="filters['searchValue']"
          @update="applyFilter('searchValue', $event)"
        )
      .download
        AppDownloadButton(disabled)
</template>

<script>
  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download")
    },

    props: {
      initiators: {
        type: Array,
        default: () => new Array()
      },
      shops: {
        type: Array,
        default: () => new Array()
      },
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      types: {
        type: Array,
        default: () => new Array()
      },
      tableWrapperClass: {
        type: String,
        default: () => new String()
      },
      filters: {
        type: Object,
        default: () => new Object()
      },
      shopsLoading: {
        type: Boolean,
        default: true
      },
      inventoryGroupsLoading: {
        type: Boolean,
        default: true
      },
      carClassesLoading: {
        type: Boolean,
        default: true
      },
      accountsLoading: {
        type: Boolean,
        default: true
      },
      dateField: {
        type: String,
        default: "date"
      },
      showInventoryGroupFilter: {
        type: Boolean,
        default: false
      },
      showShopFilter: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        initiatorsVueKey: initiator => `${initiator.id}-${initiator.type}`
      }
    },

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  ::v-deep
    .datepicker
      min-width: 200px
      width: 100%
      padding: 7px

  .top-bar.filters
    height: 100%
    padding: 0

    .filter
      +filter-title

      min-width: 210px
      max-width: 20%
      padding: 0 6px


      &-actions
        margin-top: 20px
        width: 100%
        display: flex
        align-items: center
        justify-content: space-between

        .search
          width: 400px

        .download
          margin-left: 20px
</style>
